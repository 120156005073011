import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, RoundedImage, Link } from "./"

const Wrapper = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;

    ${up("mobile")} {
      /* some reponsivity */
    }
  `}
`

class PostItemSmall extends Component {
  render() {
    const { post } = this.props
    const to = `${Link.BLOG}/${post.slug}`

    return (
      <Col maxWidth="356px" width="100%">
        <Link to={to}>
          <RoundedImage
            image={
              post.postImage.filename && post.postImage.filename.main.gatsbyImageData
            }
          />
        </Link>
        <Gap gap="18px" mobileGap="12px" />
        <Link to={to}>
          <Title.SmallerTitle as="h3">{post.title}</Title.SmallerTitle>
        </Link>
        <Gap gap="8px" />
        <Text responsive>{post.perex}</Text>
        <Gap gap="32px" mobileGap="40px" />
      </Col>
    )
  }
}

PostItemSmall.propTypes = {}

export default PostItemSmall
