import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import {
  Col,
  Row,
  Text,
  Title,
  Gap,
  RoundedImage,
  Link,
  WaveImage,
  Listing,
} from "./"
import T from "../lib/intl/T"
import ChangeItemSmall from "./ChangeItemSmall"

class ChangesSuggestions extends Component {
  render() {
    const { changes } = this.props

    // console.log('changes', changes)

    return (
      <Col
        width="100%"
        maxWidth="1010px"
        alignSelf="center"
        alignItems="flex-start"
      >
        <WaveImage />
        <Gap gap="24px" mobileGap="24px" />
        <Title.BiggerTitle>
          <T>Proměny před a po našich klientů</T>
        </Title.BiggerTitle>
        <Gap gap="24px" mobileGap="24px" />
        <Listing maxWidth="100%" gap="50px" fluid bp={720}>
          {changes.map(c => (
            <ChangeItemSmall innerKey={c._id} key={c._id} change={c} />
            // ends with 40/32 padding
          ))}
        </Listing>
      </Col>
    )
  }
}

ChangesSuggestions.propTypes = {}

export default ChangesSuggestions
