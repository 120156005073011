// external imports
import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { fragments } from '../lib/fragments'

// helpers
import { up, addResponsivity } from '../lib/styles'
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  withSameTags,
} from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent } from '../lib/post'

// components
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  RoundedImage,
  Listing,
  HexaBox,
  ContactFormSection,
  HexaImage,
  TestimonialsSection,
  GalleryPopup,
} from '../components'
import T from '../lib/intl/T'
import ChangesSuggestions from '../components/ChangesSuggestions'
import PostSuggestions from '../components/PostSuggestions'

import { PROCEDURES_INFOS } from '../lib/config'

import benefitCardImage from '../data/images/benefit-cards.png'
import { useContentImages } from '../hooks/useContentImages'

// custom styled components
const Content = styled.div`
  ${({ theme: { colors } }) => css`
    & > .p {
      margin-bottom: 8px;
    }
    & > h2 {
      color: ${colors.blue};
      font-family: 'Signika';
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
      margin-top: 24px;
      ${up('mobile')} {
        margin-top: 16px;
      }
    }
  `}
`

const BenefitCards = styled.img`
  width: 230px;
  max-width: 100%;
  height: auto;
`

function ProcedureTemplate(props) {
  const gallery = useRef(null)

  const openGallery = index => gallery?.current?.open(index)

  const {
    intl: { language, t },
    location: { pathname },
    data,
    pageContext: { allContentImages },
  } = props

  let { procedure, changes, posts, testimonials } = extractNodesFromDataApplyLangs(data, language)

  const contentImages = useContentImages()

  const contentReactElement = enhanceContent({
    content: procedure.content,
    contentImages,
  })

  // filter only posts with at least one same tag
  posts = withSameTags(posts, procedure.tags)
  posts = posts.slice(0, 3)

  // filter only changes with at least one same tag
  changes = withSameTags(changes, procedure.tags)
  changes = changes.slice(0, 2)

  // filter only testimonials with at least one same tag
  testimonials = withSameTags(testimonials, procedure.tags)
  let images = [...(procedure.images || [])]

  let nextNumber = images.length - 4
  if (nextNumber < 0) {
    nextNumber = 0
  }
  const introImage = images.shift()
  images = images.slice(0, 3)

  const ROW_BP = 1150
  const TWO_PHOTOS_BP = 785

  const pricingBox = (
    <HexaBox>
      <Text.Small>
        <T>Cena zákroku</T>
      </Text.Small>
      <Gap gap="13px" />
      {(procedure.prices || [])
        .filter(price => price.amount && price.amount !== '')
        .sort((a, b) => {
          const isAEmpty = !a.sortOrder && a.sortOrder !== 0
          const isBEmpty = !b.sortOrder && b.sortOrder !== 0
          if (isAEmpty && isBEmpty) return 0
          if (isAEmpty) return 1
          if (isBEmpty) return -1
          return a.sortOrder - b.sortOrder
        })
        .map((price, index) => (
          <React.Fragment key={price._id}>
            <Col>
              <Text.Italic>{price.name}</Text.Italic>
              <Title.SmallerTitle innerAs="span">{price.amount}</Title.SmallerTitle>
            </Col>
            <Gap gap="16px" />
          </React.Fragment>
        ))}
      <Text.Small maxWidth="230px">
        <T>Uváděná cena zákroků je orientační. Může se změnit ještě po prohlídce lékařem.</T>
      </Text.Small>
    </HexaBox>
  )

  const nextPhotosLinkText = (
    <T raw args={[nextNumber]}>
      {nextNumber < 5 ? 'další <br /> {arg1} fotografie' : 'dalších <br /> {arg1} fotografií'}
    </T>
  )

  return (
    <>
      <SEO
        title={`${procedure.name} · ${t('procedures - meta title')}`}
        pathname={pathname}
        description={procedure.metaDesc}
      />
      <Gap.Top />
      <Title.PageTitle maxWidth="750px">{procedure.name}</Title.PageTitle>
      <Gap gap="6px" mobileGap="0px" />
      <Text.Small>{procedure.subtitle}</Text.Small>
      <Gap gap="21px" mobileGap="4px" />
      <Row responsive bp={ROW_BP}>
        <Col width="100%" maxWidth="750px">
          {introImage && (
            <div style={{ cursor: 'pointer' }} onClick={openGallery}>
              <RoundedImage image={introImage.image.filename.thumb.gatsbyImageData} />
            </div>
          )}
          {procedure.images && procedure.images.length > 0 && (
            <>
              <Gap gap="16px" mobileGap="8px" />
              <Row
                justifyContent="stretch"
                alignItems="center"
                responsive
                mobileAlignItems="stretch"
              >
                <Col alignItems="stretch">
                  <Row maxWidth="566px" mobileWidth="100%" justifyContent="flex-start">
                    <GalleryPopup data={procedure.images} ref={gallery} />
                    {images.map((image, index) => (
                      <React.Fragment key={image.id}>
                        {index !== 0 && (
                          <Gap
                            gap="16px"
                            mobileGap="8px"
                            showAfter={index === 2 && TWO_PHOTOS_BP}
                          />
                        )}
                        <Col
                          width="178px"
                          mobileWidth="156px"
                          bp={620}
                          key={image.id}
                          showAfter={index === 2 && TWO_PHOTOS_BP}
                          onClick={() => openGallery(index + 1)}
                          style={{ cursor: 'pointer' }}
                        >
                          <RoundedImage small image={image.image.filename.thumb.gatsbyImageData} />
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </Col>
                <Gap gap="22px" mobileGap="24px" />
                <Col grow="0" alignItems="center">
                  {nextNumber > 0 && (
                    <Link.BasicLink asSpan onClick={() => openGallery(4)}>
                      <RH desktop>{nextPhotosLinkText}</RH>
                      <RH mobile>{nextPhotosLinkText}</RH>
                    </Link.BasicLink>
                  )}
                </Col>
              </Row>
              <Gap gap="40px" mobileGap="32px" />
            </>
          )}

          <Col maxWidth="621px">
            {/* <RH hideAfter={ROW_BP}>
                {pricingBox}
                <Gap gap="32px" />
              </RH> */}
            <Text responsive>
              <Content>{contentReactElement}</Content>
            </Text>
          </Col>
        </Col>

        {/* stredni mezera */}
        <Gap.Fluid min="48px" max="80px" showAfter={ROW_BP} />

        <Col>
          {/* <Gap gap="52px" /> */}
          {/* <RH showAfter={ROW_BP}>{pricingBox}</RH> */}
          <RH hideAfter={ROW_BP}>
            <Gap gap="38px" mobileGap="16px" />
            {pricingBox}
            <Gap gap="60px" mobileGap="32px" />
          </RH>

          {procedure.people && procedure.people.length > 0 && (
            <Col>
              <Title.SmallGrayTitle>
                <T>Zákrok provádí</T>
              </Title.SmallGrayTitle>
              <Gap gap="14px" />
              {procedure.people.map((p, index) => (
                <Row alignItems="center" margin={index % 2 !== 0 ? '0 0 0 55px' : '0'}>
                  <Col maxWidth="110px" width="100%">
                    <HexaImage image={p.photo.filename.image.gatsbyImageData} />
                  </Col>
                  <Gap gap="16px" />
                  <Col maxWidth="177px" width="100%">
                    <Title fontSize="18px" lineHeight="26px">
                      {p.name}
                    </Title>
                    <Text.Italic>{p.position}</Text.Italic>
                  </Col>
                </Row>
              ))}
            </Col>
          )}

          <Gap gap="80px" mobileGap="48px" />

          <div>
            <Title.SmallGrayTitle>
              <T>Co byste měli vědět o zákroku</T>
            </Title.SmallGrayTitle>
            <Gap gap="26px" mobileGap="18px" />

            {PROCEDURES_INFOS.filter(info => !info.condition || info.condition({ language })).map(
              (info, index) => (
                <React.Fragment key={index}>
                  <Row alignItems="center">
                    <img src={info.path} alt="" />
                    <Gap gap="24px" />
                    <Text.Small maxWidth="230px">
                      <T>{info.text}</T>
                    </Text.Small>
                  </Row>
                  <Gap gap="32px" mobileGap="24px" />
                </React.Fragment>
              ),
            )}
          </div>
          <Gap gap="48px" mobileGap="48px" />

          <RH showAfter={ROW_BP}>{pricingBox}</RH>
          {language === 'cs' && (
            <>
              <Gap gap="60px" mobileGap="0" bp={ROW_BP} />
              <Title.SmallGrayTitle>
                <T>Akceptujeme tyto benefitní karty</T>
              </Title.SmallGrayTitle>
              <Gap gap="20px" mobileGap="18px" />
              <Row width="100%" alignItems="flex-start" justifyContent="flex-start">
                <BenefitCards src={benefitCardImage} alt="benefitní karty" />
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Gap gap="80px" mobileGap="48px" />
      {changes && changes.length > 0 && (
        <>
          <ChangesSuggestions changes={changes} />
          <Gap gap="40px" mobileGap="16px" />
        </>
      )}
      {testimonials && testimonials.length > 0 && (
        <>
          <TestimonialsSection data={testimonials} />
          <Gap gap="40px" mobileGap="16px" />
        </>
      )}

      {posts && posts.length > 0 && (
        <>
          <PostSuggestions posts={posts} />
          <Gap gap="40px" mobileGap="16px" />
        </>
      )}
      <ContactFormSection />
    </>
  )
}

export default injectIntl(ProcedureTemplate)

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    procedure: procedures(id: { eq: $id }) {
      ...ProcedureFragment
      people {
        ...PersonFragment
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
    changes: allPosts(
      sort: { fields: when, order: DESC }
      filter: { state: { eq: "published" }, isChange: { eq: true }, lang: { eq: $language } }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }

    testimonials: allTestimonials(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          ...TestimonialFragment
        }
      }
    }

    # contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }

    posts: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        isChange: { eq: false }
        id: { ne: $id }
        isFoundationEvent: { ne: true }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
`
