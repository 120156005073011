import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import {
  Col,
  Row,
  Text,
  Title,
  Gap,
  RoundedImage,
  Link,
  WaveImage,
  Listing,
} from "."
import T from "../lib/intl/T"
import PostItemSmall from "./PostItemSmall"

class PostSuggestions extends Component {
  render() {
    const { posts, title = "Mohlo by vás zajímat" } = this.props

    return (
      <Col width="100%" alignSelf="center" alignItems="flex-start">
        <WaveImage />
        <Gap gap="24px" mobileGap="24px" />
        <Title.BiggerTitle>
          <T>{title}</T>
        </Title.BiggerTitle>
        <Gap gap="20px" mobileGap="24px" />
        <Listing maxWidth="100%" gap="36px" bp={890}>
          {posts.map(p => (
            <PostItemSmall innerKey={p._id} key={p._id} post={p} />
          ))}
          {/* ends with 32/40px padding */}
        </Listing>
      </Col>
    )
  }
}

PostSuggestions.propTypes = {}

export default PostSuggestions
