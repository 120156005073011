import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, RoundedImage, Link, WaveImage } from "./"

class ChangeItemSmall extends Component {
  render() {
    const { change } = this.props
    const to = `${Link.BLOG}/${change.slug}`

    return (
      <Col maxWidth="480px" width="100%" alignItems="flex-start">
        <Link to={to} width="100%">
          <RoundedImage image={change.postImage.filename?.main.gatsbyImageData} />
        </Link>
        <Gap gap="24px" mobileGap="16px" />
        <Link to={to}>
          <Title.SmallerTitle fontSize="22px" as="h3">
            {change.title}
          </Title.SmallerTitle>
        </Link>
        <Text.Italic>{change.changeSubtitle}</Text.Italic>
        <Gap gap="40px" mobileGap="32px" />
      </Col>
    )
  }
}

ChangeItemSmall.propTypes = {}

export default ChangeItemSmall
