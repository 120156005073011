import { graphql, useStaticQuery } from 'gatsby'
import { extractNodes } from '../lib/helpers'

export const useContentImages = () => {
  const data = useStaticQuery(graphql`
    fragment PostContentImage on File {
      contentImage: childImageSharp {
        fluid: gatsbyImageData(
          width: 587
          height: 330
          quality: 100
          placeholder: BLURRED
          layout: CONSTRAINED
        )
        fluidNoCrop: gatsbyImageData(
          width: 587
          quality: 100
          placeholder: BLURRED
          layout: CONSTRAINED
        )
      }
    }

    query ImageQuery {
      allContentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
        edges {
          node {
            ...PostContentImage
            relativePath
            publicURL
          }
        }
      }
    }
  `)

  const allContentImages = extractNodes(data.allContentImages)

  return allContentImages
}
